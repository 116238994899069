import React from "react"
import Layout from "../components/Layout";

const ExistingSubscriptionPage = () => {
  return (
    <Layout>
      <h1 className="text-center">Subscription Request Rejected</h1>
      <p className="text-center">Your Subscription Request failed because you already purchased this product. Please return to AWS Marketplace to ensure you have selected the right product.</p>
    </Layout>
  )
};

export default ExistingSubscriptionPage
